import echarts from "echarts/dist/echarts"
import $ from '../static'

let windowLoaded = false
$(window).on('load', function() {
  windowLoaded = true
  initEchart(document)
})

$(document).on("turbolinks:load", function() {
  if (windowLoaded) {
    initEchart(document)
  }
})

function initEchart(root) {
  $(root).find('.echart').each(function() {
    const theme = $(this).data('theme') || 'default'
    const jsonp = $(this).data('jsonp')
    const option = window[jsonp]()
    echarts.init(this, theme).setOption(option)
  })
}