import $ from './static'

function findContainer(element) {
  const containerSelector = $(element).data("cascade-container")
  return $(element).closest(containerSelector)
}

function loadTarget(target, container) {
  $(target).prop("disabled", true)
  const group = $(target).data("cascade-group")
  const groupKey = `cascade-name-${group}`
  const data = $.map($(container).find(`[data-${groupKey}]`), function(source) {
    const name = $(source).data(groupKey)
    const value = $(source).val()
    return { name: name, value: value }
  })
  $.ajax({
    url: $(target).data("url"),
    data: data,
    success: function(res) {
      const blankText = $(target).data("include-blank")
      if (blankText) {
        $(target).html($('<option>', { value: '', text: blankText }))
      }
      $.each(res, function(_, item) {
        $(target).append($('<option>', { value: item[1], text: item[0] }))
      })
      $(target).prop("disabled", false)
    }
  })
}

function cascadeSourceChanged(source) {
  const container = findContainer(source)
  $(container).find(".cascade-select").each(function(_, target) {
    if (target != source) {
      const group = $(target).data("cascade-group")
      const name = $(source).data(`cascade-name-${group}`)
      if (name) {
        loadTarget(target, container)
      }
    }
  })
}

$(function() {
  $(document).on('change', '.cascade-select-source', function() {
    cascadeSourceChanged(this)
  })
})