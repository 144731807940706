import $ from './static'

$(document).on('click', '.x_add_item', function(event) {
  event.preventDefault()
  $(this.getAttribute("data-target")).append(this.getAttribute("data-html").replace(/INDEX_FOR_REPLACE/g, new Date().getTime()))
})

$(document).on('click', '.x_destroy_item', function(event) {
  event.preventDefault()
  $(this).closest('.x_item').find('.x_destroy_input').prop('disabled', false)
  $(this).closest('.x_item').hide()
})