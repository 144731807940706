import * as ActiveStorage from "@rails/activestorage"
import $ from '../components/static'

import '../components/jquery-ujs'

$.rails.href = function (element) {
  return element.data('href') || element[0].href;
};

import '../tabler'
import '../components/turbolinks'
import '../components/flatpickr'
import '../components/fontawesome'
import '../components/auto_form'
import '../components/fields_for'
import '../components/cascade_select'
import '../components/echarts'
import '../components/select2'

import '../frontend'

ActiveStorage.start()
