import $ from '../static'

import 'select2/dist/js/select2.full.js';
import 'select2/dist/css/select2.css';
import 'select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.css';

import './style.scss'

$(document).on("turbolinks:load", function () {
  $('.combobox').each(function (index, element) {
    if ($(element).hasClass('form-control-sm')) {
      $(element).select2({ theme: 'bootstrap-5', containerCssClass: 'select2--small' });
    } else {
      $(element).select2({ theme: 'bootstrap-5' });
    }
  });
});

$(document).on('turbolinks:before-cache', function (event) {
  event.preventDefault()

  $(event.target).find('.combobox').each(function (index, element) {
    $(element).select2('destroy')
  })
})
